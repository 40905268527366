import request from '@/axios'
import type { UserLoginType, UserType } from './types'

// interface RoleParams {
//   roleName: string
// }

export const loginApi = (data: UserLoginType): Promise<IResponse<any>> => {
  return request.post({ url: '/api/login', data })
}

export const loginOutApi = (): Promise<IResponse> => {
  return request.post({ url: '/api/logout' })
}
// 修改用户基本信息
export const updateUserInfoApi = (data: UserType): Promise<IResponse<any>> => {
  return request.post({ url: '/api/user/edit', data })
}
// 修改密码
export const updatePasswordApi = (data: any): Promise<IResponse<any>> => {
  return request.post({ url: '/api/user/change-password', data })
}

// 获取登录租户信息
export const getLoginUserInfoApi = (): Promise<IResponse<any>> => {
  return request.get({ url: '/api/user' })
}
// 修改默认租户
export const changeDefaultTenantApi = (data: any): Promise<IResponse<any>> => {
  return request.post({ url: 'api/user/changeDefaultTenant', data })
}
// export const getUserListApi = ({ params }: AxiosConfig) => {
//   return request.get<{
//     code: string
//     data: {
//       list: UserType[]
//       total: number
//     }
//   }>({ url: '/mock/user/list', params })
// }

// export const getAdminRoleApi = (
//   params: RoleParams
// ): Promise<IResponse<AppCustomRouteRecordRaw[]>> => {
//   return request.get({ url: '/mock/role/list', params })
// }

// export const getTestRoleApi = (params: RoleParams): Promise<IResponse<string[]>> => {
//   return request.get({ url: '/mock/role/list2', params })
// }
